const currentcyNormal = {
  // privacy_default_value: function () {
  //   return (val, rule, fApi) => {
  //     rule.display = fApi.form.privacy_fix_user === 1
  //   }
  // }
}
const update = {
  /**
   * 不能有空格
   * @param {object} vm 表单的vue实例对象
  */
  nickname: {
    normal: {
      maxLength: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.validate !== "";
        };
      }
    }
  },
  "base-time-picker": {
    normal: {
      dateType: function () {
        return (val, rule, fApi) => {
          fApi.form.dateType = fApi.form.dateType === undefined ? 2 : fApi.form.dateType
        };
      },
      ...currentcyNormal
    }
  },
  "base-area-picker": {
    normal: {
      showType: function () {
        return (val, rule, fApi) => {
          fApi.form.showType = fApi.form.showType === undefined ? 1 : fApi.form.showType
        };
      },
      ...currentcyNormal
    }
  },
  "base-input": {
    normal: currentcyNormal
  },
  "base-textarea": {
    normal: currentcyNormal
  },
  email: {
    normal: currentcyNormal
  },
  "base-select": {
    normal: currentcyNormal
  },
  birthday: {
    normal: currentcyNormal
  },
  phone: {
    normal: currentcyNormal
  },
  gender: {
    normal: currentcyNormal
  },
  page: {

  }
};

export default update;
