<template>
  <el-col class="small-form" :span="24">
    <el-form-item :label="label">
      <div class="flex-col" style="align-items: flex-end; width: 100%; ">
        <el-cascader :style="{maxWidth}" :options="dataList" :props="optionProp"
                     v-model="myValue" collapse-tags clearable @change="handleChange" />
        <p class="info" :style="{width: maxWidth}">
          <i class="el-icon-info" />{{info}}
        </p>
      </div>
    </el-form-item>
  </el-col>
</template>

<script>
import { getCityData } from '../../../api/template-design'

export default {
  name: 'StCityData',
  props: {
    value: Array,
    label: String,
    maxWidth: {
      type: String,
      default: ""
    },
    info: String,
  },
  data() {
    return {
      dataList: [],
      optionProp: {
        expandTrigger: "hover",
        multiple: true,
        emitPath: false,
      },
    }
  },
  computed: {
    myValue: {
      get() {
        return this.value
      },
      set(e) {
        this.$emit('input', e)
      }
    }
  },
  created() {
    getCityData().then(res => {
      this.dataList = res.data
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    handleChange(e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>

</style>
