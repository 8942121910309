<template>
  <div class="st-bottom-button">
    <div class="button-content">
      提交
    </div>
  </div>
</template>

<script>
export default {
   name: "st-bottom-button",
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          btnText: "",
          interactiveType: "",
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  created() {
    // console.log('st-nav', this.config, JSON.stringify(this.config));
  },
};
</script>

<style lang="scss" scoped>
.st-bottom-button {
  padding: 16px;
  width: 100%;

  .button-content {
    text-align: center;
    width: 100%;
    color: #ffffff;

    height: 42px;
    line-height: 42px;
    border-radius: 54px;
    background-color: $primary;

      font-size: 16px;
      font-weight: 700;
  }
}
</style>
