<template>
  <div class="form-page" v-loading="loading" :class="header.isFullscreen ? 'fullscreen':''" :style="leftBarWidth">
    <el-container class="layout">
      <!-- 顶部菜单栏 -->
      <el-header class="menu-bar" style="height: 54px;">
        <page-header-content ref="header" @header-click="handleHeaderClick"/>
      </el-header>
      <el-container class="workspace">
        <div class="workspace-left" style="width: auto; max-width: 50%; min-width: var(--left-barwidth);">
          <!-- 左侧菜单 -->
          <div class="resize-bar"/>
          <div class="resize-line"/>
          <div class="resize-save">
            <!-- 左侧菜单 -->
            <page-left v-model="main.selected" />
          </div>
        </div>
        <el-main class="workspace-middle">
          <page-middle v-model="main.selected" ref="pageMiddle" :page="main.page" :bottomBtn="main.bottomBtn"
                       :list="main.componentList" @update-list="updateList"/>
        </el-main>
        <el-aside class="workspace-right" style="width: 360px;">
          <page-right :selected="main.selected" :page="main.page" :bottomBtn="main.bottomBtn"
                      :list="main.componentList" @update-list="updateList"/>
        </el-aside>
      </el-container>
    </el-container>
    <PreviewCodeDialog v-model="showPreviewqrCode" :data="qrCodeData"/>
  </div>
</template>

<script>
import { mapState } from "vuex";

import PageMiddle from "../components/template-design/Page/PageMiddle";
import { getFormConfig, saveFormConfig } from "../api/template-design";
import PageHeaderContent from "../components/template-design/Page/PageHeaderContent";
import PageLeft from "../components/template-design/Page/PageLeft";
import PageRight from "../components/template-design/Page/PageRight";
import PreviewCodeDialog from "../components/template-design/Page/PreviewCodeDialog";
import Compare from "@/base/utils/object-compare";

let library = require("../data/component-library.json");
const defaultList = require("../data/default-data.json").default;
let {page, bottomBtn} = JSON.parse(JSON.stringify(library));

export default {
  components: {
    PageMiddle,
    PageRight,
    PageLeft,
    PageHeaderContent,
    PreviewCodeDialog,
  },
  data() {
    return {
      loading: false,
      header: {
        isFullscreen: true,
      },
      pageDetail: {
        id: '',
        // config: {
        //   page: {},
        //   list: defaultList,
        // },
        form_configs: []
      },
      main: {
        selected: "page",
        page: JSON.parse(JSON.stringify(page)),
        bottomBtn: JSON.parse(JSON.stringify(bottomBtn)),
        componentList: [],
      },
      qrCodeData: {},
      showPreviewqrCode: false,
      leftBarWidth: "--left-barwidth: " + "285px" + "; ",
    };
  },
  computed: {
    ...mapState("approvalTemplate", ["library"]),
  },
  watch: {
    "pageDetail.id"(val) {
      if (val == "0") {
        const storageDataStr = localStorage.getItem('form_configs') || '{}'
        const storageData = JSON.parse(storageDataStr).form_configs
        if (typeof storageData === 'number') {
        } else {
          this.main.componentList = JSON.parse(storageDataStr).form_configs || []
        }
      } else {
        this.getTemplateField(val);
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.pageDetail.id = this.$route.params.id || '0';
    });

    this.$store.commit(
      "approvalTemplate/setStateAttr",
      {
        key: 'library',
        val: JSON.parse(JSON.stringify(library))
      }
    );
  },
  mounted() {
    let that = this;
    /**
     * 从Firefox 4、 Chrome 51、Opera 38 和Safari 9.1开始，通用确认信息代替事件返回的字符串。
     * 也就是不能自定义
     * */
    window.onbeforeunload = function (e) {
      console.log("on before unload");
      e = e || window.event; // 兼容IE8和Firefox 4之前的版本
      const article = that.getNowArticleData();
      if (Compare(that.elderData, article)) {
        console.log("no change");
      } else {
        console.log("modified");
        if (e) {
          e.returnValue = "当前页面有修改，是否放弃更改？";
        } // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return "当前页面有修改，是否放弃更改？";
      }
      console.log(article);
    };
  },
  methods: {
    // 获取表单配置详情
    getTemplateField(id) {
      this.loading = true;
      getFormConfig(id)
        .then((res) => {
          const {data} = res;
          if (data && JSON.stringify(data) === '[]') {

          } else {
            this.pageDetail.form_configs = data;
            this.main.componentList = data
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async handleHeaderClick(key) {
      switch (key) {
        case "save": {
          try {
            await this.switchPage();
          } catch (e) {
            console.log(e)
          }
          break;
        }
        case "fullscreen":
          // this.header.isFullscreen = !this.header.isFullscreen;
          break;
        default:
      }
    },
    /**
     * 更新组件列表
     */
    updateList(arr) {
      this.main.componentList = arr;
    },
    // 保存
    switchPage() {
      this.loading = true;
      const saveData = {...this.main};
      let flag = false;
      // 表单标题查重
      saveData.componentList.forEach((el1, i) => {
        saveData.componentList.forEach((el2, j) => {
          if (
            (el2.props.normal.label || el2.props.title) ===
            (el1.props.normal.label || el1.props.title) &&
            i !== j
          ) {
            if (!flag) {
              this.main.selected = i;
              this.$refs.pageMiddle.errFormConfig(i);
              this.$message.error("存在标题重复的表单");
              this.loading = false;
              flag = true;
            }
          }
        });
      });
      if (flag) {
        // 查重不通过，走 catch
        return Promise.reject()
      }

      if (this.$route.params.id != 0) {
        const data = {
          id: this.$route.params.id,
          form_configs: (saveData.componentList)
        };
        saveFormConfig(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.loading = false;
            let value = saveData.componentList.length ? 1 : 0
            localStorage.setItem('form_configs', JSON.stringify({
              form_configs: value
            }))
            this.$refs.header.closeWindow()
            return Promise.resolve()
          })
          .catch(() => {
            this.loading = false;
            return Promise.reject()
          });
      } else {
        localStorage.setItem('form_configs', JSON.stringify({
          form_configs: saveData.componentList
        }))

        // this.$message.success('已保存')
        this.$confirm('已保存，是否关闭页面？', '', {
          type: 'success',
          confirmButtonText: '是',
          cancelButtonText: '否'
        }).then(() => {
          /* 是否自动关闭浏览器标签页 */
          this.$refs.header.closeWindow()
        }).catch(() => {})
        this.loading = false
        console.log('set local data...', localStorage.getItem('form_configs'))
        return Promise.resolve()
      }
    },
  },
};
</script>

<style lang="scss">
  .form-page {
    overflow-x: auto;
    margin: calc(-#{$space} * 2);
    background-color: #eef2f8;

    /* 全屏 */
    &.fullscreen {
      position: fixed;
      left: calc(#{$space} * 2);
      top: calc(#{$space} * 2);
      height: 100vh;
      width: 100%;
      z-index: 11;

      .workspace {
        height: calc(100vh - 54px);
      }
    }

    .menu-bar {
      background-color: $primary;
    }

    /* 顶部菜单栏下面的一整个容器 */
    .workspace {
      height: calc(100vh - 60px - 50px - 54px);
    }

    .page-left,
    .page-right {
      background-color: white;
    }

    .workspace-left {
      overflow-x: hidden;
      overflow-y: hidden;
      position: relative;
      float: left;
    }

    .el-main.workspace-middle {
      padding: 0;
      overflow: hidden;
    }
  }

  .resize-save {
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
  }

  .resize-bar {
    width: var(--left-bar-width);
    min-width: var(--left-bar-width);
    max-width: 100%;
    /*height: inherit;*/
    height: calc(100vh - 54px);
    resize: horizontal;
    cursor: col-resize;
    opacity: 0;
    overflow: scroll;
  }

  /* 拖拽线 */
  .resize-line {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 2px solid #eee;
    border-left: 1px solid #bbb;
    pointer-events: none;
  }

  .resize-bar:hover ~ .resize-line,
  .resize-bar:active ~ .resize-line {
    border-left: 1px dashed #3a76ff;
  }

  .resize-bar::-webkit-scrollbar {
    width: 200px;
    height: inherit;
  }

  /* Firefox只有下面一小块区域可以拉伸 */
  @supports (-moz-user-select: none) {
    .resize-bar:hover ~ .resize-line,
    .resize-bar:active ~ .resize-line {
      border-left: 1px solid #bbb;
    }
    .resize-bar:hover ~ .resize-line::after,
    .resize-bar:active ~ .resize-line::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      bottom: 0;
      right: -8px;
      /*background: url(./resize.svg);*/
      /*background-size: 100% 100%;*/
    }
  }
</style>
