<template>
  <div class="base-form-item">
    <div class="base-form-item-box">
      <div class="base-form-item-inner" :class="[vertical ? 'vertical' : 'inline']">
        <div class="base-form-item-label">
          {{handleSaaSVersionKeyword(label) || ''}}
          <span v-if="required" class="form-required">*</span>
        </div>
        <div class="base-form-item-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFormItem",
  props: {
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Number,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
  $page-side: 16px;

.base-form-item {
  width: 100%;
  height: 100%;
  padding: 0 $page-side;

  .base-form-item-box {
    padding: 0 4px;
    height: 100%;
    background-color: white;
  }

  .base-form-item-inner {
    height: 100%;
    display: flex;
    min-height: 56px;

    &.inline {
      justify-content: space-between;

      .base-form-item-label {
        @include nowrap();
      }
    }

    &.vertical {
      flex-direction: column;

      .base-form-item-label {
        padding-bottom: 0;
      }

      .base-form-item-content {
        width: 100%;
      }
    }
  }

  $label-row-gap: 16px;

  .base-form-item-label {
    min-width: 70px;
    font-size: 14px;
    font-weight: bold;
    color: #191e29;
    position: relative;
    padding: $label-row-gap 0 $label-row-gap 8px;

    .form-required {
      /*position: absolute;*/
      /*left: 0;*/
      font-size: 14px;
      font-weight: bold;
      color: #dc193e;
      line-height: inherit;
    }
  }

  .base-form-item-content {
    flex: 1;
    min-width: 70px;
  }
}
</style>
