<template>
  <div class="page-right">
    <div class="right-name" v-if="isListSelected">{{list[selected].title + "设置"}}</div>
    <div class="right-name" v-else-if="selected === 'page'">页面设置</div>
    <div class="right-name" v-else-if="selected === 'bottomBtn'">按钮设置</div>
    <div class="right-name" v-else></div>
    <div class="workspace-right-content">
      <!-- 页面设置 -->
      <st-form-editor v-show="selected === page.selectedName" v-model="page.props"
        :component="page.component" field="page">
      </st-form-editor>
      <!-- 按钮设置 -->
      <st-form-editor v-show="selected === bottomBtn.selectedName" v-model="bottomBtn.props"
        :component="bottomBtn.component">
      </st-form-editor>
      <!-- 自定义页面 -->
      <div v-for="(ed, index) in list" :key="ed.extra.timestamp">
        <st-form-editor v-show="index === selected" v-model="ed.props" :component="ed.component"
          :type="ed.type" :field="ed.field" />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import StFormEditor from "./StFormEditor";

export default {
  name: "PageRight",
  components: {
    StFormEditor,
    draggable,
  },
  props: {
    selected: {
      type: [String, Number],
      default: -1,
    },
    pageDetail: Object,
    list: Array,
    page: Object,
    bottomBtn: Object,
  },
  data() {
    return {
      hover: -1,
      activeIcon: require("../../../assets/images/del_blue.png"),
      icon: require("../../../assets/images/del_gray.png"),
    };
  },
  computed: {
    isListSelected() {
      let sel = this.selected;
      return typeof sel === "number" && sel >= 0;
    }
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.page-right {
  position: relative;
  height: calc(100vh - 54px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 120px;
}

.right-name {
  font-weight: bold;
  line-height: 80px;
  background-color: #f7f7f7;
  position: sticky;
  top: 0;
  z-index: 5;
  height: 80px;
  color: #3a3a3a;
  font-size: 18px;
  padding: 0 24px;
}

.workspace-right-content {
  padding: 20px 19px 0 24px;

  .component-list-editor {
  }

  .component-list-controls {
    text-align: right;
  }

  /* 占位 */
  .ghost-component-item {
    height: 1px !important;
    /*width: 60%;*/
    overflow: hidden;
    color: $primary !important;
    position: relative;
    box-shadow: 0 2px 4px 0 #d5deeb;
    opacity: 0.8;
    background-color: white;
    padding-top: 10px;

    &::before {
      content: "";
      /*width: 8px;*/
      /*height: 8px;*/
      /*border-radius: 50%;*/
      height: 1px;
      width: 100%;
      border-top: 1px solid $primary;
      /*position: absolute;*/
    }
  }

  /* 拖拽时 */
  .chosen-component-item {
    background-color: white;
    /*box-shadow: 0 2px 4px 0 #D5DEEB;*/
    /*opacity: 0.8;*/
    cursor: move;
  }

  .component-list {
    .component-item {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 8px;
      color: #3a3a3a;

      &:hover {
        background-color: #e9f1fd;
        color: $primary;

        .component-item-name {
        }
      }

      .component-item-name {
        font-size: 14px;
        line-height: 14px;
      }

      .component-item-del {
        width: 20px;
        min-width: 20px;
      }
    }
  }
}
</style>
