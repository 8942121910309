<template>
  <div class="page-header-content">
    <!-- TODO: 点击修改透明度 -->
    <!-- 左侧 -->
    <div class="menu-bar-left">
      <!-- 退出编辑按钮：点击时返回到页面装修页 -->
      <el-button class="menu-bar-btn" @click="closeWindow">
        <span class="menu-bar-btn-content">
          <img src="../../../assets/images/exit.png" />
          <span class="menu-bar-btn-text">退出编辑</span>
        </span>
      </el-button>
    </div>
    <!-- 中部 -->
    <div class="menu-bar-middle"></div>
    <!-- 右侧 -->
    <div class="menu-bar-right">
      <!-- TODO: 进入/退出全屏时图标切换 -->
<!--      <el-button class="menu-bar-btn" @click="toggleFullscreen">-->
<!--        <span class="menu-bar-btn-content">-->
<!--          <img src="../../../assets/images/fullscreen.png" />-->
<!--          <span>{{isFullscreen ? '退出' : ''}}全屏</span>-->
<!--        </span>-->
<!--      </el-button>-->
      <el-button class="menu-bar-btn" @click="$emit('header-click', 'save')">
        <span class="menu-bar-btn-content">
          <img src="../../../assets/images/save.png" />
          <span>保存</span>
        </span>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeaderContent",
  data() {
    return {
      isFullscreen: false,
    };
  },
  mounted() {
    let that = this;
    window.onresize = function () {
      if (!that.isFullscreenState()) that.isFullscreen = false;
    };
  },
  methods: {
    /**
     * [isFullscreen 判断浏览器是否全屏]
     * @return [全屏则返回当前调用全屏的元素,不全屏返回false]
     */
    isFullscreenState() {
      return (
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        false
      );
    },
    /**
     * 退出编辑
     */
    closeWindow() {
      window.opener = null;
      window.close();
    },
    /**
     * 发布
     */
    // publish() {
    //   let platform = this.$route.params.platform;
    //   this.$confirm(
    //     "是否发布（" +
    //       (platform.includes("weixin")
    //         ? "发布后系统自动提审微信小程序，无需手动提交，审核时间 3 天左右，具体以腾讯审核时间为准"
    //         : platform.includes("h5")
    //         ? "发布后自动更新 H5 页面"
    //         : "") +
    //       "）",
    //     "提示",
    //     {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       type: "warning",
    //     }
    //   )
    //     .then(() => {
    //       this.$emit("header-click", "publish", {platform});
    //     })
    //     .catch(() => {
    //       console.log("cancel");
    //     });
    // },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.fullScreen();
      } else {
        this.exitScreen();
      }
      this.$emit("header-click", "fullscreen");
    },
    // 全屏
    fullScreen() {
      var el = document.documentElement;
      var rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
      }
    },
    // 退出全屏
    exitScreen() {
      var el = document.documentElement;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header-content {
  display: flex;
  justify-content: space-between;

  .menu-bar-left,
  .menu-bar-middle,
  .menu-bar-right {
    display: flex;
    min-width: 33.3%;
  }

  .menu-bar-left {
    .menu-bar-btn {
      &:first-child {
        margin-left: 17px;
      }
    }
  }

  .menu-bar-middle {
    justify-content: center;
  }

  .menu-bar-right {
    justify-content: flex-end;
  }

  .menu-bar-btn {
    background-color: unset;
    border-color: transparent;
    color: white;
    height: 54px;
    display: flex;
    align-items: center;

    .menu-bar-btn-content {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        margin-right: 7px;
      }

      .menu-bar-btn-text {
        line-height: 54px;
      }
    }
  }
}
</style>
