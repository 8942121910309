<template>
  <div class="st-select st-form-item" :style="{
    marginBottom: myConfig.normal.marginBottom + 'px'
  }">
    <base-form-item :label="myConfig.normal.label">
      <div v-if="field === 'attachment'" class="content flex-align-center" style="justify-content: flex-end;">
        <i class="el-icon el-icon-circle-plus-outline" style="color: #B3B3B3;" />
      </div>
    </base-form-item>
  </div>
</template>

<script>
  import BaseFormItem from "./BaseFormItem";
  export default {
    name: "StSelect",
    components: {BaseFormItem},
    props: {
      config: {
        type: Object,
        default: function () {
          return {};
        },
      },
      field: String
    },
    data() {
      return {
        // 定义 config 格式和默认数据
        model: {
          normal: {
            "marginBottom": 0,
            "required": true,
            "label": "标题内容",
            "placeholder": "",
            "validate": "",
            "option": [""]
          },
        }
      }
    },
    computed: {
      myConfig() {
        return this.$mergeModel(this.model, this.config);
      },
    },
  }
</script>

<style lang="scss" scoped>
  .st-select {
    .content {
      height: 100%;
      padding-right: 4px;

      .el-icon {
        font-size: 18px;
      }
    }
  }
</style>
