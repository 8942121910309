<template>
  <div class="page-left">
    <div class="left-name">自定义表单组件</div>
    <page-component-library :selected="value" />
  </div>
</template>

<script>
import PageComponentLibrary from "./PageComponentLibrary";
// import PageComponentSelect from "./PageComponentSelect.vue";

export default {
  name: "PageLeft",
  components: { PageComponentLibrary,
    // PageComponentSelect
  },
  props: {
    // main.selected
    value: {
      type: [Number, String],
    },
  },
};
</script>

<style lang="scss">
.page-left {
  height: 100%;

  .left-name {
    font-weight: bold;
    line-height: 80px;
    background-color: #f7f7f7;
    position: sticky;
    top: 0;
    z-index: 5;
    height: 80px;
    color: #3a3a3a;
    font-size: 18px;
    padding: 0 24px;
    white-space: nowrap;
  }
}
</style>
