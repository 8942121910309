import { api } from "@/base/utils/request";

export const getFormConfig = (id) => {
  return api({
    url: '/admin/approval/tmpl/getFormConfigs',
    method: 'post',
    data: {id}
  })
}

export const saveFormConfig = (data) => {
  return api({
    url: '/admin/approval/tmpl/saveFormConfigs',
    method: 'post',
    data
  })
}

export const getCityData = () => {
  return api({
    url: '/admin/admin/data/getCityJson',
    method: 'post',
    notCancel: true
  })
}
