<template>
  <div class="st-nav">
    <div class="header" :style="{
			opacity: isShowFixedNav ? 0 : 1
		}">
      <div class="header-content">
        <div style="flex: 1">
        </div>
        <div class="title" :style="'color:' + myConfig.normal.titleColor">
          审批</div>
        <div style="flex: 1"><img class="handle-icon" src="../../../assets/images/wechat-handle1@2x.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "st-page-set",
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      isShowFixedNav: false,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          title: "页面设置",
          titleColor: "black",
        },
        share: {
          title: "",
          description: "",
          image: "",
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  created() {
    // console.log('st-nav', this.config, JSON.stringify(this.config));
  },
};
</script>

<style lang="scss" scoped>
.st-nav {
  width: 100%;
  overflow: hidden;

  .header {
    position: relative;
    padding: 20px 11px 0;
    transition: opacity 0.5s;
    width: 100%;
    background-color: transparent;
    z-index: 2;

    .header-content {
      height: 44px;
      display: flex;
      justify-content: space-between;
      div {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .title {
        flex: 1;
        justify-content: center;
      }

      .back-icon {
        width: 20px;
        height: 20px;
        margin-right: auto;
      }
      .handle-icon {
        width: 87px;
        height: 32px;
        margin-left: auto;
      }
    }
  }
}
</style>
